import { getStoreAccessors } from 'typesafe-vuex'
import { dispatchKwikCheckApiError } from 'kwik-vue/store/main/actions'
import { ActionContext } from 'vuex'
import { RootState } from '@/store'
import productionOrdersRepository from '../repository'
import { ProductionOrdersState } from './state'
import { commitSetProductionOrdersCurrentPage } from './mutations'
import { IBatchesForProductionGetInput, IProductionOrderCreatePayload, IProductionOrdersGetInput } from '../types'

type MainContext = ActionContext<ProductionOrdersState, RootState>;

export const actions = {
  async actionGetProductionOrdersCurrentPage (context: MainContext, payload: IProductionOrdersGetInput) {
    try {
      const response = await productionOrdersRepository.getAll(payload)
      if (response) {
        commitSetProductionOrdersCurrentPage(context, response.data)
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionGetProductionOrderForTimeline (context: MainContext) {
    try {
      const response = await productionOrdersRepository.getAllForTimeline()
      if (response) {
        return response.data
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionGetProductionOrderDuration (context: MainContext, payload: IProductionOrderCreatePayload) {
    try {
      const response = await productionOrdersRepository.getProductionOrderDuration(payload)
      if (response) {
        return response.data
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionGetProductionOrdersBatches (context: MainContext, productionOrdersId: number) {
    try {
      const response = await productionOrdersRepository.getBatchesByProductionOrdersId(productionOrdersId)
      if (response) {
        return response.data
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionGetBatchesForProduction (context: MainContext, payload: IBatchesForProductionGetInput) {
    try {
      const response = await productionOrdersRepository.getBatchesForProduction(payload)
      if (response) {
        return response.data
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionInsertProductionOrder (context: MainContext, payload: IProductionOrderCreatePayload) {
    try {
      const response = await productionOrdersRepository.insertProductionOrder(payload)
      if (response) {
        return response.data
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionCancelProductionOrder (context: MainContext, productionOrderId: number) {
    try {
      const response = await productionOrdersRepository.cancelProductionOrder(productionOrderId)
      if (response) {
        return response.data
      }
    } catch (error) {
      await dispatchKwikCheckApiError(context, error)
    }
  },
  async actionCanFrezee () {
    try {
      const response = await productionOrdersRepository.getCanFrezee()
      if (response) {
        return response.data
      }
    } catch (error) {
      // await dispatchKwikCheckApiError(context, error)
    }
  }
}

const { dispatch } = getStoreAccessors<ProductionOrdersState | any, RootState>('')

export const dispatchGetProductionOrdersCurrentPage = dispatch(actions.actionGetProductionOrdersCurrentPage)
export const dispatchGetProductionOrderForTimeline = dispatch(actions.actionGetProductionOrderForTimeline)
export const dispatchGetProductionOrderDuration = dispatch(actions.actionGetProductionOrderDuration)
export const dispatchGetProductionOrdersBatches = dispatch(actions.actionGetProductionOrdersBatches)
export const dispatchGetBatchesForProduction = dispatch(actions.actionGetBatchesForProduction)
export const dispatchInsertProductionOrder = dispatch(actions.actionInsertProductionOrder)
export const dispatchCancelProductionOrder = dispatch(actions.actionCancelProductionOrder)
export const dispatchCanFrezee = dispatch(actions.actionCanFrezee)
