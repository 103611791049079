import repository from '@/repositories/appAxios'
import { composePaginationQuery } from 'kwik-vue/utils/urlQuery'
import { IWarehouseGetInput } from '../types'

const resource = '/company/warehouse'
const config = {
  headers: {
    'x-universe': '1'
  }
}

export default {
  getAll (getInput: IWarehouseGetInput) {
    const pagingQuery = composePaginationQuery(getInput.paging)
    let filterQuery = ''
    if (getInput.sorting.sorting) filterQuery += `&sorting=${encodeURIComponent(getInput.sorting.sorting)}`
    return repository.get(`${resource}?${pagingQuery}${filterQuery}`, config)
  },
  getMaterials () {
    const filterQuery = ''
    return repository.get(`/company/warehouse_materials?${filterQuery}`, config)
  },
  canImprove (companyWarehouseId: number) {
    return repository.get(`/company/company_warehouse_models/can_improve?warehouse_model_id=${companyWarehouseId}`, config)
  },
  improveWarehouse (companyWarehouseId: number) {
    return repository.put(`/company/company_warehouse_models/improve?warehouse_model_id=${companyWarehouseId}`, {}, config)
  },
  buyWarehouse (companyWarehouseId: number) {
    return repository.put(`/company/company_warehouse_models/buy?warehouse_model_id=${companyWarehouseId}`, {}, config)
  }
}
