import repository from '@/repositories/appAxios'
import { composePaginationQuery } from '@/utils/pagination'
import { INotificationGetInput } from '../types'

const resource = 'company/notifications'
const config = {
  headers: {
    'x-universe': '1'
  }
}

export default {
  getAll (getInput: INotificationGetInput) {
    const pagingQuery = composePaginationQuery(getInput.paging)
    let filterQuery = ''
    if (getInput.sorting.sorting) filterQuery += `&sorting=${encodeURIComponent(getInput.sorting.sorting)}`
    if (getInput.message) filterQuery += `&message=${encodeURIComponent(getInput.message)}`
    if (getInput.archived) filterQuery += `&archived=${getInput.archived}`
    return repository.get(`${resource}?${pagingQuery}${filterQuery}`, config)
  },
  archive (id: number) {
    return repository.post(`${resource}/archive?notification_id=${id}`, {}, config)
  }
}
